/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

/** lib */
import Loading from '../UI/Loading'
import { forgotEmail } from '../../services/auth.service'

/** state */
import { login } from '../../actions/auth.actions'
import { 
    getSavedRecipes,
    getUserMealplans, 
    getShoppinglists, 
    getSharedWithMe,
    getUserRecipes 
} from '../../actions/user.actions'

export default function LoginForm() {
    const dispatch = useDispatch()
    
    const { isLoggedIn, loginError } = useSelector(state => state.auth)    
    const [forgotPasswordState, setForgotPasswordState] = useState(false)
    const [unmaskPasswordState, setUnmaskPasswordState] = useState('password')

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState(false)
    const [resetEmailSentSuccess, setResetEmailSentSuccess] = useState(false)
    const [resetEmailErrorMessage, setResetEmailSentErrorMessage] = useState('')


    const onHandleSendResetEmail = () => {
        setLoading(true)

        // @todo pre validate email address

        forgotEmail({ email }).then((r) => {
            if(r?.status === 404) {
                setResetEmailSentSuccess(false)
                setResetEmailSentErrorMessage('Error Finding Account')
            } else {
                setResetEmailSentSuccess(true)
                setResetEmailSentErrorMessage('')
            }
        }).catch((r) => {
            setResetEmailSentSuccess(false)
            setResetEmailSentErrorMessage('Error Finding Account')
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()

        setLoading(true)

        dispatch(login({ email, password })).then((r) => {
            dispatch(getSavedRecipes())
            dispatch(getUserMealplans())
            dispatch(getShoppinglists())
            dispatch(getSharedWithMe())
            dispatch(getUserRecipes())
           
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    if (isLoggedIn) {
        return <Navigate to="/user-dashboard" />
    }

    return (
        <div className="mt-8 w-4/5 mx-auto">
            <style scoped>
                {`
                    input {
                        all: unset;
                        background: #000 !important;
                        color: #fff;
                        border: 1px solid #fff;
                        width: 100%; 
                        box-sizing: border-box;
                        -webkit-box-sizing:border-box;
                        -moz-box-sizing: border-box;
                    }
                    
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        transition: all 5000s ease-in-out 0s;
                    }
                `}
            </style>
            <form onSubmit={handleSubmit}>
                {
                    forgotPasswordState && !resetEmailSentSuccess &&
                    <h2 className="mb-4 text-white text-center">Forgot Password.</h2>
                }

                {
                    forgotPasswordState && resetEmailSentSuccess &&
                    <h2 className="mb-4 text-white text-center">Password reset email sent</h2>
                }

                <div className="mb-4 w-full">
                    <label className="block text-sm font-light mb-2 text-white text-center" htmlFor="username">
                        Email
                    </label>
                    <input
                        className="rounded w-full py-2 px-3 leading-tight bg-black text-white text-center" 
                        id="email" 
                        type="email" 
                        placeholder="Email Address"
                        onChange={e => setEmail(e.target.value)} 
                    />
                </div>
                
                {
                    !forgotPasswordState &&
                        <div className="w-full relative">
                            <label className="block text-white text-sm font-light mb-2 text-center w-full" htmlFor="password">
                                Password
                            </label>
                            <input 
                                className="rounded w-full py-2 px-3 mb-3 leading-tight text-center"
                                id="password" 
                                type={unmaskPasswordState} 
                                placeholder=""
                                onChange={e => setPassword(e.target.value)} 
                            />

                            <div className="absolute right-2 bottom-[17px]">
                                {
                                    unmaskPasswordState === 'password'
                                        ? 
                                            <button  type="button" onClick={() => setUnmaskPasswordState('text') }>
                                                <EyeIcon className="text-white w-4 h-4"/>
                                            </button> 
                                        : <button className="text-white" type="button" onClick={() => setUnmaskPasswordState('password') }>
                                            <EyeOffIcon className="text-white w-4 h-4" />
                                        </button> 
                                }  
                            </div>
                        </div>
                }

                {forgotPasswordState &&
                    <div className={`${resetEmailSentSuccess ? 'text-white': 'text-red-500' } font-light text-sm text-center px-8 h-8`}>
                        {                                  
                            (resetEmailSentSuccess || resetEmailErrorMessage) && resetEmailSentSuccess 
                                ? 'Please check your email' : resetEmailErrorMessage
                        } 
                    </div>
                }

                {!forgotPasswordState && <div className="text-red-500 text-center text-sm h-8">{loginError}</div> }

                <div className="flex flex-col w-full items-center">
                    {
                        !forgotPasswordState &&
                            <>
                                <button 
                                    className={`${loading ? 'opacity-50' : ''} bg-black text-white px-4 rounded h-9 border border-white w-[200px]`} 
                                    type="submit" 
                                    disabled={loading}
                                >
                                    { loading ? <Loading className="my-2" /> : 'Login' }
                                </button>

                                <a  onClick={() => { setForgotPasswordState(true) }}
                                    className="inline-block align-baseline text-xs text-white underline mt-4" 
                                    href="#"
                                >
                                    Forgot Password?
                                </a>
                            </>
                    }

                    {
                        forgotPasswordState && !resetEmailSentSuccess &&
                            <>
                                <button
                                    onClick={() => { onHandleSendResetEmail()} } 
                                    className={`${loading ? 'opacity-50' : ''} bg-black text-white px-4 rounded h-9 border border-white w-[200px] text-sm font-light`} 
                                    type="button" 
                                    disabled={loading}
                                >
                                    { loading ? <Loading className="my-2" /> : 'Send Reset Email' }
                                </button>

                                <a  onClick={() => { setForgotPasswordState(false) }}
                                    className="inline-block align-baseline text-xs text-white underline mt-4" 
                                    href="#"
                                >
                                    Cancel
                                </a>
                            </>
                    }
                    {
                        forgotPasswordState && resetEmailSentSuccess &&
                            <a onClick={() => { setForgotPasswordState(false); setResetEmailSentSuccess(false) }}
                                className="inline-block align-baseline text-xs text-white underline mt-4" 
                                href="#"
                            >
                                Return to Login
                            </a>
                    }
                </div>
            </form>
        </div>   
    )
}

