/** vendor */
import React, { useState } from 'react'

/** components */
import StepZeroWelcome from './TutorialDialogs/StepZeroWelcome'
import StepOnePreferences from './TutorialDialogs/StepOnePreferences'
import StepTwoFoodStores from './TutorialDialogs/StepTwoFoodStores'
import StepThreeMealplans from './TutorialDialogs/StepThreeMealplans'
import StepFourShoppingLists from './TutorialDialogs/StepFourShoppingLists'
import StepFiveCreateAndShare from './TutorialDialogs/StepFiveCreateAndShare'

const FirstLoginDialog = ({ onConfirm, onCancel }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [step, setStep] = useState(0)

  const handleComplete = () => {
    setIsOpen(false)
    onCancel()
  }

  const handleContinue = () => {
    setStep(step+1)
    onConfirm()
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  return (
    <div
      className={`fixed z-40 inset-0 p-2 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div className="flex flex-col bg-white p-8 rounded-md shadow-md w-96 h-[96vh] max-w-full z-50">
        <h2 className="text-xl font-semibold mb-4 text-center">Welcome to aisle nine</h2>
        <div className="flex-grow">
          { step === 0 && <StepZeroWelcome /> }
          { step === 1 && <StepOnePreferences /> }
          { step === 2 && <StepTwoFoodStores /> }
          { step === 3 && <StepThreeMealplans /> }
          { step === 4 && <StepFourShoppingLists /> }
          { step === 5 && <StepFiveCreateAndShare /> }
        </div>
        <div className="flex flex-col">
          { step === 0 &&
            <>
              <button
                className="mb-2 px-4 py-2 text-white bg-black border"
                onClick={handleContinue}
              >
                Show me how this works
              </button>
              <button
                className="px-4 py-2 text-black" 
                onClick={handleCancel}
              >
                I can figure it out myself
              </button>
            </>
          }

          { 
            step > 0 && step < 5 &&
              <button
                className="px-4 py-2 text-black" 
                onClick={handleContinue}
              >
                Continue
              </button>
          
          }

          { 
            step === 5 &&
              <button
                className="px-4 py-2 text-black" 
                onClick={handleComplete}
              >
                Complete
              </button>
          }
        </div>
      </div>
    </div>
  )
}

export default FirstLoginDialog
