import React, { useEffect, useState } from 'react';

const TimerInput = ({h, m, s, onUpdate}) => {
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [seconds, setSeconds] = useState('')

    // useEffect(() => {
    //     if (h || m || s) {
    //         setHours(h || '00')
    //         setMinutes(m || '00')
    //         setSeconds(s || '00')
    //     }
    // }, [h, m, s])

    useEffect(() => {
        onUpdate(`${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`)
    }, [hours, minutes, seconds])

    const handleInputChange = (event, type) => {
        const inputValue = event.target.value
        
        const sanitizedValue = (parseInt(inputValue, 10)) ? inputValue
            .replace(/\D/g, '').slice(0, 2) : ''
        
        if (type === 'hours') {
            setHours(sanitizedValue)
        }  
        
        if (type === 'minutes') {
            setMinutes(sanitizedValue)
        } 

        if (type === 'seconds') {
            setSeconds(sanitizedValue)
        }
    }

    return (
        <div className="flex justify-center w-full">
            <div className="w-1/3 pr-1">
                <input
                    type="text"
                    value={hours}
                    onChange={(event) => handleInputChange(event, 'hours')}
                    placeholder="hours"
                    maxLength="2"
                    className="w-full rounded border-black border text-center"
                />
                <small className="block py-1 first-line:text-xs text-black text-center">
                    Hours
                </small>
            </div>

            <div className="w-1/3 px-1">
                <input
                    type="text"
                    value={minutes}
                    onChange={(event) => handleInputChange(event, 'minutes')}
                    placeholder="minutes"
                    maxLength="2"
                    className="w-full rounded border-black border text-center"
                />
                <small className="block py-1 first-line:text-xs text-black text-center">
                    Minutes
                </small>
            </div>

            <div className="w-1/3 pl-1">
                <input
                    type="text"
                    value={seconds}
                    onChange={(event) => handleInputChange(event, 'seconds')}
                    placeholder="seconds"
                    maxLength="2"
                    className="w-full rounded border-black border text-center"
                />
                <small className="block py-1 first-line:text-xs text-black text-center">
                    seconds
                </small>
            </div>

           


        </div>
    )
}

export default TimerInput