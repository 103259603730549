
/** vendor */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SearchIcon } from '@heroicons/react/solid'

export default function CreateBasicDetailsForm({onUpdate, recipe}) {
    const recipeOptions = useSelector(state => state.form_options?.recipe_options)

    const [loading, setLoading] = useState(false)
    const [formFragment, setFormFragment] = useState({
        name: '',
        description: '',
        recipe_image: '',
        serves: 1
    })

    /**
     * Hooks
     */
    useEffect(() => {
        if (recipe?.name) {
            setFormFragment({
                name: recipe?.name || '',
                description: recipe?.description || '',
                recipe_image: recipe?.recipe_image || '',
                serves: recipe?.serves || 1
            })
        }
    }, [recipe])


    const handleOnChange = async e => {
        e.preventDefault()
        const formFragmentCopy = {...formFragment}
        formFragmentCopy[e.target.id] = e.target.value
        
        setFormFragment(formFragmentCopy)
        onUpdate(formFragmentCopy)
    }

    const handleSearch = async e => {
        e.preventDefault()
        const formFragmentCopy = {...formFragment}
        formFragmentCopy[e.target.id] = e.target.value
        setFormFragment(formFragmentCopy)
        onUpdate(formFragmentCopy)
    }

    return (
        <div className="min-w-full h-[60vh] pt-4">
            <div className="flex min-w-full justify-center"> 
                <div className="w-full mr-2 px-8">
                        <div className="mb-4">
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-black">Recipe Name</label>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="name"
                                    value={formFragment?.name} 
                                    className="bg-white border border-black text-black text-sm rounded-lg block w-full pr-10 p-2.5" 
                                    placeholder="Recipe name ..."
                                    onChange={handleSearch}
                                />
                                <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                                    <SearchIcon className="h-6 w-6" />
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="description" className="block mb-2 text-sm font-medium text-black">Description</label>
                            <textarea
                                value={formFragment?.description} 
                                onChange={handleOnChange}
                                id="description" 
                                rows="4" 
                                className="block p-2.5 w-full text-sm text-black rounded-lg border border-black" 
                                placeholder="Description..."></textarea>
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-black" htmlFor="user_avatar">Upload file</label>
                            <input 
                                className="block w-full overflow-hidden text-sm text-black rounded-lg border border-black cursor-pointer" 
                                aria-describedby="recipe_image_help" 
                                id="recipe_image" 
                                type="file"
                                value={formFragment?.recipe_image}  
                                onChange={handleOnChange}
                            />
                            <div 
                                className="mt-1 text-sm text-black" 
                                id="recipe_image_help"
                            >
                                (Optional) a picture of the completed meal
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

