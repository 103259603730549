/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

/** lib */
import { Card } from '../../components/UI/Card'
import Stepper from '../../components/UI/Stepper'
import Loading from '../../components/UI/Loading'

/** state */
import { getFormOptions } from '../../actions/form.actions'

/** components */
import CreateBasicDetailsForm from '../../components/Forms/RecipeCreator/CreateBasicDetailsForm'
import CreateIngredientsForm from '../../components/Forms/RecipeCreator/CreateIngredientsForm'
import ConfigureIngredientsForm from '../../components/Forms/RecipeCreator/ConfigureIngredientsForm'
import CreateStepsForm from '../../components/Forms/RecipeCreator/CreateStepsForm'
import SaveRecipeForm from '../../components/Forms/RecipeCreator/SaveRecipeForm'
import { createUserRecipe, getUserRecipes } from '../../actions/user.actions'

export default function RecipeCreator() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

	const [currentStep, setCurrentStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [formErrors, setFormErrors] = useState(null)
  const [validSteps, setValidSteps] = useState([])

  const [recipe, setRecipe] = useState({
    category_id: null,
    name: '',
    ingredients: [],
    steps: [],
    serves: 1,
    recipe_image: null
  })
  
  const stepArray = [
    {
      description: 'Basic Details',
      to: '/recipe-creator' 
    },
    {
      description: 'Add Ingredients',
      to: '/recipe-creator/ingredients' 
    },
    {
      description: 'Configure Ingredients',
      to: '/recipe-creator/configure' 
    },
    {
      description: 'Add Steps',
      to: '/recipe-creator/steps' 
    },
    {
      description: 'Review and Save',
      to: '/recipe-creator/complete' 
    }
	]

  /**
   * Utils
   */
  const validateRecipe = () => {
    const validSteps = []
    const errors = {}
  
    if (!recipe.name || recipe.name.trim() === '') {
      errors.name = 'Recipe name is required'
    }

    if (!recipe.description || recipe.description.trim() === '') {
      errors.description = 'Recipe description is required'
    }

    if(!errors?.description && !errors?.name) {
      validSteps.push(0)
    }
  
    if (!recipe.ingredients || recipe.ingredients.length === 0) {
      errors.ingredients = 'At least one ingredient is required'
    } else {
      validSteps.push(1)
      validSteps.push(2)
    }

    if (!recipe.steps || recipe.steps.length === 0) {
      errors.steps = {}
      errors.steps.error = 'At least one step is required'
    } else {
      recipe.steps.forEach((step, index) => {
        if (!step.title || step.title.trim() === '') {
          if(!errors?.steps) errors.steps = {}
          errors.steps[`step_${index}_title`] = `Step ${index + 1} title is required`
        }
        if (!step.description || step.description.trim() === '') {
          if(!errors?.steps) errors.steps = {}
          errors.steps[`step_${index}_description`] = `Step ${index + 1} description is required`
        }
      })
    }

    if(Object.keys(errors).length === 0) {
      validSteps.push(3)
      validSteps.push(4)
      setIsValid(true)
      setFormErrors(null)
    } else {
      setIsValid(false)
      setFormErrors(errors)
    }

    setValidSteps(validSteps)    
  }

  const saveFormProgress = () => {
    localStorage.setItem('draft_recipe', JSON.stringify(recipe))
  } 

  useEffect(() => {
    validateRecipe()
  }, [recipe])

  useEffect(() => {
    if(currentStep > 1) {
      saveFormProgress()
    }
  }, [currentStep])


  /**
   * Hooks
   */
  useEffect(() => {
    setLoading(true)

    dispatch(getFormOptions()).then(() => {
        setLoading(false)
    }).catch(() => {
        setLoading(false)
    })

    setCurrentStep(stepArray.findIndex(i => i.to === location.pathname) + 1)
  }, [])

  /**
   * Handlers
   */
  const handleSubmitRecipe = () => {
    console.warn('handleSubmitRecipe', recipe)
    dispatch(createUserRecipe(recipe)).then(() => {
      dispatch(getUserRecipes()).then(() => {
        // @todo add toasts
        // redirect to created recipe
      })
    })
  }

	const handleClick = (clickType) => {
		let newStep = currentStep;

		(clickType == "next") ? newStep++ : newStep--

		// Check if steps are within the boundary
		if (newStep > 0 && newStep <= stepArray.length) {
			setCurrentStep(newStep)
      navigate(stepArray[newStep - 1].to)
		}
	}

  const onUpdateFormState = (newState) => {
    setRecipe({...recipe, ...newState})
  }

  if (loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full relative">
      <Card title="Create Recipe" enableBack={false}>
          <Stepper steps={stepArray} currentStepNumber={currentStep} validSteps={validSteps}/>
          <div className="m-h-[72vh] overflow-y-scroll flex justify-center items-center">
              <Routes> 
                <Route path="/" element={<CreateBasicDetailsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/ingredients" element={<CreateIngredientsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/configure" element={<ConfigureIngredientsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/steps" element={<CreateStepsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/complete" element={<SaveRecipeForm onSubmit={onUpdateFormState} onCancel={() => { console.warn('todo: onCancel') }} recipe={recipe} />} />
              </Routes>
          </div>
      </Card>

      <div className="fixed bottom-0 left-0 w-screen py-4 flex justify-between px-2">
          <button
            disabled={currentStep < 2} 
            onClick={() => handleClick()} 
            className={`${currentStep < 2 ? 'opacity-60' : ''} text-black bg-white rounded font-medium border border-black text-sm px-5 py-2`}
          >Back</button>
          
          <button
            disabled={currentStep === stepArray?.length && !isValid} 
            onClick={() => currentStep === stepArray?.length ? handleSubmitRecipe() : handleClick("next") } 
            className={`${(currentStep === stepArray?.length && !isValid) ? 'opacity-60' : ''} text-white bg-black rounded font-medium border border-black text-sm px-5 py-2`}
          >
            {
              currentStep === stepArray?.length ? 'Submit' : 'Next'
            }
          </button>
      </div>

      {
        saving ? <div className="h-full w-full fixed top-0 left-0 opacity-60 bg-white flex items-center justify-center pt-60"><Loading title="Saving" /></div> : ''
      }
    </div>
  )
}