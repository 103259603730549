/** vendor */
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

/** lib */
import TimerInput from '../UI/TimerInput'

const initialStep = { 
  id: null, 
  title: '', 
  description: '', 
  timer: '',
  step_image: null 
}

const CreateStepDialog = ({ 
  showDialog = false, 
  step, 
  onUpdate = () => {}, 
  onCancel = () => {} 
}) => {
  const [tab, setTab] = useState('required')
  const [isOpen, setIsOpen] = useState(true)
  const [currentStep, setCurrentStep] = useState(initialStep) 

  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  const handleChange = (e) => {
    const currentStepCopy = JSON.parse(JSON.stringify(currentStep))
    currentStepCopy[e.target.id] = e.target.value
    setCurrentStep(currentStepCopy)
  }

  const setStepId = () => {
    const currentStepCopy = JSON.parse(JSON.stringify(currentStep))
    currentStepCopy.id = uuidv4()
    setCurrentStep(currentStepCopy)
  }

  /**
   * Hooks
   */
  useEffect(() => {    
    setIsOpen(showDialog)
    if(showDialog && !currentStep?.id) { 
      setStepId()
    } 
    if (showDialog && step) {
      setCurrentStep(step)
    }
  }, [showDialog, step])

  /**
   * Handlers
   */
  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  const handleOnTimerChange = (newValue) => {
    const currentStepCopy = JSON.parse(JSON.stringify(currentStep))
    currentStepCopy['timer'] = newValue
    setCurrentStep(currentStepCopy)
  }

  const handleConfirm = () => {
    onUpdate(currentStep)
    setIsOpen(false)
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bottom-0 bg-black opacity-60"></div>
      <div className="fixed left-0 w-full bottom-3 px-2">
        <div className="bg-white p-4 rounded-md shadow-md z-50">
          <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">{currentStep?.stepId ? 'Edit Step' : 'Create Step'}</h2>

          <ul className="w-full flex justify-center flex-wrap text-sm font-medium text-center border-b pb-2">
            <li className="w-1/3 text-center">
                <a 
                  href="#required" 
                  aria-current="page"
                  onClick={handleToggleTab}  
                  className={`${tab === 'required' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                >
                    Required
                </a>
            </li>
            <li className="w-1/3 text-center">
                <a 
                  href="#additional"
                  onClick={handleToggleTab} 
                  className={`${tab === 'additional' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                >
                  Additional
                </a>
            </li>
          </ul>

          <div className="flex min-w-full justify-center"> 
              <div className="w-full">
                { 
                  tab === 'required' && 
                    <>
                      <div className="mb-4 w-full">
                          <label htmlFor="name" className="block mb-2 text-sm font-medium text-black">Name</label>
                          <div className="relative">
                              <input 
                                  type="text" 
                                  id="title"
                                  value={currentStep?.title}
                                  onChange={handleChange} 
                                  className="border border-black text-black text-sm rounded block w-full pr-10 p-2.5" 
                                  placeholder="Step Name"
                              />
                          </div>
                      </div>

                      <div className="mb-4 w-full">
                          <label htmlFor="description" className="block mb-2 text-sm font-medium text-black">Description</label>
                          <textarea 
                              id="description" 
                              rows="4"
                              onChange={handleChange}
                              value={currentStep?.description} 
                              className="block p-2.5 w-full text-sm text-black rounded border border-black" 
                              placeholder="Step Description"></textarea>
                      </div>
                    </>  
                }

                { 
                  tab === 'additional' && 
                    <>
                      <div className="mb-4 items-center">
                        <div className="w-40 mx-auto h-36 border border-black rounded-sm mt-1 flex-grow">

                        </div>
                        <div className="pt-2 border-t mt-1">
                          <label className="block mb-2 text-xs font-medium text-black" htmlFor="user_avatar">Upload file</label>
                          <input 
                              className="block w-full overflow-hidden text-xs text-black rounded-lg border border-black cursor-pointer" 
                              aria-describedby="step_image_help" 
                              id="step_image" 
                              type="file"
                              value={step?.step_image}  
                              onChange={handleChange}
                          />
                          <div 
                              className="mt-1 text-xs text-black" 
                              id="step_image_help"
                          >
                              (Optional) a picture of the step
                          </div>

                        </div>


                      </div>

                      <div className="w-full pt-1 border-t mt-1">
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-black">Timer</label>
                        <TimerInput onUpdate={handleOnTimerChange} />
                      </div>
                    </>
                }
              </div>
          </div>
        </div>

        <div className="flex justify-between mt-2 w-full">
          <button
            className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateStepDialog
