
/** vendor */
import React, { useState } from 'react'
import { TrashIcon } from '@heroicons/react/solid'

export default function IngredientListItem({ ingredient, onRemove = () => {} }) {

    /**
     * Handlers
     */
    const handleRemoveIngredient = async e => {
        e.preventDefault()
        onRemove(ingredient)
    }

    const button = <TrashIcon className="h-6 w-6 text-red-600 cursor-pointer" onClick={handleRemoveIngredient} />

    return (
        <li className="py-3 sm:py-4 border-b min-w-full" key={ingredient.value}>
            <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                    <img className="w-8 h-8 rounded-full border overflow-hidden" src={ingredient.image} alt="ingredient image" />
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-light text-black">
                        {ingredient.name}
                    </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-black">
                    { button }
                </div>
            </div>
        </li>
    )
}