/** vendor */
import React, { useEffect, useState } from 'react'
import { Card } from '../../components/UI/Card'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/solid'

/** state */
import { 
    deleteMealplan, 
    getUserMealplans, 
    getSharedWithMe 
  } from '../../actions/user.actions'

import MealplanIngredientSumaryDialog from '../Dialogs/MealplanIngredientSummaryDialog'
import MealplanPanelListItem from './MealplanPanelListItem'
import Loading from '../UI/Loading'

export default function MealplanPanel() {
    const dispatch = useDispatch()

    const mealplans = useSelector(state => state.user_data.mealplans)
    const sharedWithMeMealplans = useSelector(state => state.user_data?.shared_with_me?.filter(m => m.type === 'UserMealplan'))
    const foodstore = useSelector(state => state.user_data?.user_stores.find(s => s?.default))    

    const [init, setInit] = useState(false)

    const [loading, setLoading] = useState(false)
    
    const [now, setNow] = useState(new Date())
    const [next, setNext] = useState(new Date())

    const [showIngredientSummaryDialog, setShowIngredientSummaryDialog] = useState(false)
    const [ingredientSummaryRecipes, setIngredientSummaryRecipes] = useState([])

    const [hasToday, setHasToday] = useState(false)
    const [hasTomorrow, setHasTomorrow] = useState(false)

    const [formattedPlansToday, setFormattedPlansToday] = useState(null)
    const [formattedPlansTomorrow, setFormattedPlansTomorrow] = useState(null)

    /**
     * Hooks
     */
    useEffect(() => {
        if(init)
        {
            if(!formattedPlansToday)
            {
                const plansToday = [...mealplans, ...sharedWithMeMealplans].filter(m => now >= new Date(m.start_date) || now <= new Date(m.end_date))
                
                for(const plan of plansToday)
                {
                    const hasT = plan.recipes?.filter(
                        r => new Date(r.date).toDateString() === now.toDateString() && r.recipes?.length > 0
                    )?.length > 0
        
                    if(hasT) {
                        setHasToday(true)
                        break
                    }
                }
    
                setFormattedPlansToday(plansToday) 
            }

            if(!formattedPlansTomorrow)
            {
                const plansTomorrow = [...mealplans, ...sharedWithMeMealplans].filter(m => now >= new Date(m.start_date) || now <= new Date(m.end_date))    
        
                for(const plan of plansTomorrow)
                {
                    const hasTd = plan.recipes?.filter(
                        r => new Date(r.date).toDateString() === next.toDateString() && r.recipes?.length > 0
                    )?.length > 0
        
                    if(hasTd) {
                        setHasTomorrow(true)
                        break
                    }
                }
                setFormattedPlansTomorrow(plansTomorrow)
            }
        }
    }, [init])

    useEffect(() => {
        const d = new Date()
        d.setDate(next.getDate() + 1)
        setNext(d)

        setLoading(true)
        
        dispatch(getUserMealplans())
            .then((r) => {
                dispatch(getSharedWithMe())
                    .then((r) => {
                        setInit(true)
                        setLoading(false) 
                    }).catch(() => {    
                        setLoading(false)
                    })
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    /**
     * Handlers
     */
    const handleOnShowIngredientSummary = (e, mealplan, recipes) => {
        const recipe_ids = recipes.map(r => r.value)
        const summaryRecipes = []
    
        recipe_ids.forEach(id => {
          const recipeDetail = mealplan.recipes.find(r => r.recipe_id === id)
          summaryRecipes.push(recipeDetail)
        })
    
        setIngredientSummaryRecipes(summaryRecipes)
        setShowIngredientSummaryDialog(true)
    }

    return (
        <Card title="Upcoming Meals" enableBack={false} autoHeight={true}>
            <div className="overflow-y-auto relative pb-6" style={{ height: 'calc(100vh / 4)' }}>
                {
                    loading &&
                    !hasToday && 
                    !hasTomorrow && 
                        <div className="pt-16"><Loading /></div>
                }
                <div>
                    {
                        hasToday && formattedPlansToday?.length > 0 &&
                            <div>
                                <label className="w-full block px-1 py-2 bg-gray-100 font-light text-xs">
                                    Today: {now.toLocaleDateString("en-GB", { 
                                                weekday: 'short', 
                                                year: 'numeric', 
                                                month: 'short', 
                                                day: 'numeric' 
                                            })}
                                </label>
                                { 
                                    formattedPlansToday
                                        .map((plan, index) => {
                                            const planCopy = {...plan}
                                            planCopy.recipes = planCopy.recipes?.filter(
                                                r => new Date(r.date).toDateString() === now.toDateString()
                                            )

                                            return (
                                                <div key={index}>
                                                    <MealplanPanelListItem plan={planCopy} i={index} />
                                                </div>
                                            )
                                        })
                                }
                            </div>
                    }

                    { 
                        hasTomorrow && formattedPlansTomorrow?.length > 0 &&
                            <div>
                                <label className="w-full block px-1 py-2 bg-gray-100 font-light text-xs">
                                    Tomorrow: {next.toLocaleDateString("en-GB", { 
                                                weekday: 'short', 
                                                year: 'numeric', 
                                                month: 'short', 
                                                day: 'numeric' 
                                            })}
                                </label>
                                { 
                                    formattedPlansTomorrow
                                        .map((plan, index) => {
                                            const planCopy = {...plan}
                                            planCopy.recipes = planCopy.recipes?.filter(
                                                r => new Date(r.date).toDateString() === next.toDateString()
                                            )

                                            return (
                                                <div key={index}>
                                                    <MealplanPanelListItem plan={planCopy} i={index} />
                                                </div>
                                            )
                                        })
                                }
                            </div>
                    }

                    {
                        !loading &&
                        !hasToday &&
                        !hasTomorrow &&
                        formattedPlansTomorrow?.length < 1 && 
                        formattedPlansToday?.length < 1 ?
                            <div className="opacity-60 absolute left-0 bottom-0">
                                <h1 className="text-center text-xl mt-4">No mealplans yet</h1>

                                <div className="text-right mt-2 absolute left-0 bottom-0 w-full">
                                    <Link to="/user-meal-plans">
                                        <button
                                        type="button" className="text-black bg-white font-medium text-xs underline"
                                        >Manage Mealplans</button>
                                    </Link>
                                </div>
                            </div>
                            :
                            <div className="text-right mt-2 absolute left-0 bottom-0 w-full">
                                <Link to="/user-meal-plans">
                                    <button
                                    type="button" className="text-black bg-white font-medium text-xs underline"
                                    >Manage Mealplans</button>
                                </Link>
                            </div>
                    }       
                </div>
            </div>

            {/* <MealplanIngredientSumaryDialog 
                showDialog={showIngredientSummaryDialog} 
                title="Ingredient Summary" 
                recipes={ingredientSummaryRecipes}
                onClose={() => { setShowIngredientSummaryDialog(false) }}
            /> */}

        </Card>
    )
}