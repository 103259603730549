/** vendor */
import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import { formatRecipe_SEO_JSON } from '../../utils/recipeSchema'

/** components */
import RecipeStep from './RecipeStep'
import RecipeDetailItem from './RecipeDetailItem'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import IngredientsDropdown from '../UI/IngredientsDropdown'

export default function RecipeDetail({ recipe }) {    
    return (
        <div className="w-full flex justify-center">
            <script type="application/ld+json">{JSON.stringify(formatRecipe_SEO_JSON(recipe))}</script>
            <div className="flex flex-col-reverse w-full">
                <div className="w-full">
                    <div className="flex">
                        <RecipeDetailItem recipe={recipe?.current_recipe} />
                    </div>
                    <div>
                        { 
                            recipe.current_recipe.recipe_steps
                                .sort((a, b) => a.step_order > b.step_order ? 1 : -1)
                                .map((s) => 
                                    <RecipeStep 
                                        step={s} 
                                        key={s.recipe_step_id} 
                                        ingredientList={recipe.current_recipe.recipe_ingredients}
                                    />
                            )
                        }
                    </div>
                </div>

                <IngredientsDropdown ingredients={recipe.current_recipe.recipe_ingredients} />
            </div>
        </div>  
    )
}