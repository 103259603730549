
import React, { useEffect, useState } from 'react'
import Loading from '../../UI/Loading';

export default function SaveRecipeForm({onSubmit, onCancel, recipe}) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
    }, [recipe])

    const checkIngredients = () => {
        // console.warn('checkIngredients', recipe?.ingredients)
        // make a request to the server to fetch the selected ingredients and identify the base recipe tatgs
    }

    useEffect(() => {
        checkIngredients()
    }, [recipe])

    const recipeHeader = (recipe) => (
        <div className="w-full p-1 px-2 mb-2">
            <small className="text-left text-black uppercase font-semibold pb-2 block">Basic info</small>
            <div className="flex-1 border-b border-black cursor-pointer flex px-2">   
                <div className="w-12 h-12 border border-black rounded-sm mt-1 mr-2"></div>
                <div>                                                
                    <div className="font-medium">{ recipe.name }</div>
                    <div className="text-left flex-wrap text-sm flex-shrink-1 py-1">{recipe.description}</div>
                    <small className="text-left text-xs pb-2 block">Serves: {recipe.serves}</small>
                </div>               
            </div>
        </div>
    )

    const ingredientSummary = (ingredients) => {
        return (
            <div className="w-full px-2 mb-2">
                <small className="text-left text-black uppercase font-semibold pb-2 block">ingredients ({ingredients?.length})</small>
                {
                    ingredients?.map((ingredient, index) => {
                        return (
                            <div
                                key={index} 
                                className="px-4 pt-1 flex justify-between w-full text-xs text-center font-light text-black cursor-pointer leading-none"
                            >
                                 <span>{ingredient?.name}</span> <span>{ingredient?.quantity} {ingredient?.measurement}, {ingredient?.treatment}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const stepSummary = (steps) => {
        return (
            <div className="w-full px-2 pt-2 mb-2">
                <small className="text-left text-black uppercase font-semibold pb-2 block">steps ({steps?.length})</small>

                {
                    steps?.map((step, index) => {
                        return (
                            <div className="flex items-center justify-between space-x-4 px-4 pt-1" key={index}>
                                <small className="text-xs font-light text-black">
                                    {step.title}
                                </small>
                                <small className="text-xs font-light text-black">{step?.timer}</small>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const nutritionSummary = (verifiedTags) => {
        return (
            <div className="flex w-full justify-between">
                <small className="text-left text-black uppercase font-semibold pb-2 block">matched tags</small>
                <Loading title="fetching tags ..." />
                {/* <div className="flex">
                    { vegetarian && !vegan ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">V</span> : <></> }
                    { vegan ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">VG</span> : <></> }
                    { glutenFree ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">GF</span> : <></> }
                    { sugarFree ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">SF</span> : <></> }
                    { 
                        allergens.length > 0  
                            ? <div className="text-orange-400 opacity-40 text-xs h-6 pt-1 flex items-center text-center">
                                <ExclamationCircleIcon className="h-6 w-6" onClick={() => {handleOnOpenAllergenDialog(allergens)} }/>
                            </div> : <></> 
                    }                     
                </div> */}
            </div>
        )
    }

    return (
        <div className="w-full">
            <div>{ recipe && recipeHeader(recipe) }</div>
            <div>{ recipe?.ingredients && ingredientSummary(recipe?.ingredients) }</div>
            <div>{ recipe?.steps && stepSummary(recipe?.steps) }</div>
        </div>
    )
}

