// export const API_URL = 'http://192.168.178.100:8000/api';
// export const IMG_URL = 'http://192.168.178.100:8000/uploaded_files';

export const API_URL = 'http://localhost:8000/api';
export const IMG_URL = 'http://localhost:8000/uploaded_files';

export const getApiToken = () => {
    const user = localStorage.getItem('user');
    return (user) ? JSON.parse(user).token : null;
}

export const treatment_options = [
    'whole',
    'crushed',
    'powdered',
    'muddled',
    'diced',
    'peeled',
    'sliced',
    'cooked',
    'melted',
    'boiled',
    'grated'
]

export const measurement_options = [
    'unit(s)',
    'kg',
    'g',
    'mg',
    'l',
    'ml',
    'cup(s)',
    'rasher(s)',
    'tsp',
    'tbsp'
]

export const measurement_select_options = [
    {label: 'unit(s)', value: 'unit'},
    {label: 'kg', value: 'kg'},
    {label: 'g', value: 'g'},
    {label: 'mg', value: 'mg'},
    {label: 'l', value: 'l'},
    {label: 'ml', value: 'ml'},
    {label: 'cup(s)', value: 'cup'},
    {label: 'rasher(s)', value: 'rasher'},
    {label: 'tsp', value: 'tsp'},
    {label: 'tbsp', value: 'tbsp'}
]