import SaveRecipeForm from '../Forms/SaveRecipeForm'
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

export default function RecipeDetailItem({recipe, canSave = true}) {
    const vegan = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.vegan)
    }) === 'undefined'

    const vegetarian = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.vegetarian)
    }) === 'undefined'    

    const sugarFree = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.diabetes_ok)
    }) === 'undefined'

    const glutenFree = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.gluten_free)
    }) === 'undefined'

    const recipeImage = (recipe?.image) ? `${IMG_URL}/${recipe.image}` : PLACEHOLDER_IMG

    return (
        <div className="w-full rounded">
            <div className="flex w-full justify-between">
                <small className="text-left text-gray-400 text-xs pb-2 block">Serves: {recipe?.serves}</small>
                <small className="text-left text-gray-400 pb-2 block">Category: {recipe?.category?.name}</small>
            </div>
            <div className="mb-4">
                <div className="text-left flex-wrap text-sm text-gray-800 whitespace-pre-wrap flex-shrink-1 py-1">{recipe.description}</div>
            </div>

            <div className="flex-1 border-black border-b flex flex-col w-full">    
                <div className="flex flex-row justify-end">
                    <div className="flex pb-2 pr-2">
                        {/* <span className="border border-black py-1 mr-1 text-black text-xs w-6 h-6 text-center rounded-full">V</span>
                        <span className="border border-black py-1 mr-1 text-black text-xs w-6 h-6 text-center rounded-full">VG</span> 
                        <span className="border border-black py-1 text-black text-xs w-6 h-6 text-center rounded-full">GF</span>
                        <span className="border border-black py-1 ml-1 text-black text-xs w-6 h-6 text-center rounded-full">SF</span> */}

                        { vegetarian && !vegan ? <span className="border border-black py-1 mr-1 text-black text-xs w-6 h-6 text-center rounded-full">V</span> : <></> }
                        { vegan ? <span className="border border-black py-1 mr-1 text-black text-xs w-6 h-6 text-center rounded-full">VG</span> : <></> }
                        { glutenFree ? <span className="border border-black py-1 text-black text-xs w-6 h-6 text-center rounded-full">GF</span> : <></> }
                        { sugarFree ? <span className="border border-black py-1 ml-1 text-black text-xs w-6 h-6 text-center rounded-full">SF</span> : <></> }
                    </div>
                    { canSave && <SaveRecipeForm recipe_id={recipe?.recipe_id} /> }
                </div>
            </div>
        </div>
    )
}