import { ChevronLeftIcon } from '@heroicons/react/solid'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const Card = ({ 
  title, 
  children,
  status = null,  
  onBack = () => {},
  enableBack = true,
  autoHeight = false 
}) => {
  const navigate = useNavigate()
  const { isLoggedIn, loginError } = useSelector(state => state.auth)    

  const handleBack = () => {
    try {
      navigate(-1)
      onBack()
    } catch (error) {
      if(isLoggedIn) {
        navigate('/user-dashboard')
      } else {
        navigate('/home')
      }

      onBack()
    }
  }

  return (
    <div className="flex flex-col w-full px-2 lg:px-10">        
        <div 
          className="bg-white w-full shadow-md border border-gray-400 rounded" 
        >
          {
            title &&
              <div className="flex p-2 w-full justify-between items-center bg-gray-50">
                {
                  enableBack ? 
                    <button 
                      className="w-12 h-6 text-black"
                      onClick={() => handleBack() }
                    >
                      <ChevronLeftIcon className="w-6" />
                    </button>
                  : <button className="w-12 h-6"></button>
                }
                <h5 className="text-md text-center truncate font-light">
                  { title }
                </h5>
                <div className="w-12 h-6 flex justify-end">
                  { status }
                </div>
              </div>
          }
          <hr className="pt-2" />
          <div
            style={{height: autoHeight ? 'auto' : 'calc(100vh - 188px)'}}
            className="px-2 pb-2 overflow-y-auto"
          >
            { children }
          </div>
        </div>
    </div>
  )
}