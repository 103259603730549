import React, { useState } from 'react'

export default function HomeNewsTab({ news }) {
    return (
        <div className="h-[120px]  items-center w-full mx-auto">
            {

                <section className="antialiased text-gray-600 px-4">
                    <div className="flex flex-col justify-center pt-4">
                        <div className="p-3">
                            <div>
                                { 
                                    news ?
                                        news.map((newsItem, index) => {
                                            return (
                                                <div key={index} className={`border-b py-4 my-4 border-black`}>
                                                    <small className="text-left text-gray-400 pb-2 block">{newsItem?.created_at.toLocaleString()}</small>

                                                    <h2 className="font-semibold text-4xl">{newsItem?.title}</h2>
                                                    <div dangerouslySetInnerHTML={{__html: newsItem?.HtmlContent}}></div>
                                                </div>
                                            )
                                        })
                                        : 'Slow news day' 
                                }
                            </div>
                            
                        </div>
                    </div>
                </section>
            }
        </div>
    )
}