import React, {useEffect, useState} from 'react'
import { useDebounce } from '../../hooks/useDebounce'

export default function SearchForm({ currentSearchString = null, onSearch = (searchString) => {} }) {
    const [searchString, setSearchString] = useState(currentSearchString ?? '')

    useDebounce(() => {
        onSearch(searchString)
      }, [searchString], 800
    );

    const handleOnChange = (newValue) => {
        setSearchString(newValue)
    }

    return (
        <div className="flex justify-center items-center w-full">
            <div className="mb-2 pt-2 w-full">
                <div className="relative">
                    <input 
                        type="text" 
                        id="recipeSearchInput" 
                        className="border border-black text-black text-sm rounded-lg block w-full p-2.5" 
                        placeholder="Search ..."
                        value={searchString}
                        onChange={(e) => { handleOnChange(e.target.value) }}
                    />
                </div>
            </div>
        </div>
    )
}

