import { API_URL, requestHeader } from '../constants/ApiConfiguration'

export const login = (payload) => {
    return fetch(API_URL + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(payload)
    }).then((response) => {
        return response.json()
    }).then((r) => {
        localStorage.setItem('token', r.token)
        localStorage.setItem('user', JSON.stringify(r.user))

        return r
    })
}

export function logout() {
    return fetch(API_URL + '/logout', {
        method: 'POST', 
        headers: requestHeader()
    }).then((response) => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('saved_recipes')

        window.location.href = '/login'
        window.location.reload()
    }) 
}

export function forgotEmail(payload) {
    return fetch(API_URL + '/forgot-email', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then((response) => {
        return response
    }) 
}

export function resetEmail(payload) {
    return fetch(API_URL + '/reset-email', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then((response) => {
        return response
    }) 
}

export function resetPassword(token, payload) {
    return fetch(API_URL + '/reset-password', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify({...payload, ...{token: token}})
    }).then((response) => {
        return response
    }) 
}

export function verifyEmail(payload) {
    return fetch(API_URL + '/verify-email', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then((response) => {
        return response
    }) 
}

export function validateBetaCode(payload) {
    return fetch(API_URL + '/validate-beta', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then((response) => {
        return response
    }) 
}

export function register(payload) {
    return fetch(API_URL + '/register-beta', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then((response) => {
        return response
    }) 
}