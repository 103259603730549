/** vendor */
import React, { useState, useEffect } from 'react'
import { TrashIcon, PencilAltIcon } from '@heroicons/react/solid'
import { IMG_URL, PLACEHOLDER_IMG } from '../../../constants/ApiConfiguration'

/** components */
import CreateStepDialog from '../../Dialogs/CreateStepDialog'

export default function CreateStepsForm({onUpdate, recipe}) {
    const [showDialog, setShowDialog] = useState(false)
    const [editStep, setEditStep] = useState(undefined);
    const [steps, setSteps] = useState(recipe?.steps || []);

    /**
     * Handlers
     */
    const handleOnUpdate = step => {
        const stepIndex = steps?.findIndex(s => s?.id === step?.id)

        if (stepIndex > -1) {
            const stepsCopy = [...steps]
            stepsCopy[stepIndex] = step
            setSteps(stepsCopy)
        } else {
            setSteps([...steps, step])
            onUpdate({steps: [...steps, step]})
        }

        setShowDialog(false)
    }

    const handleRemoveStep = index => {
        const stepsCopy = [...steps]
       
        stepsCopy.splice(index, 1)
        setSteps(stepsCopy)
        onUpdate({steps})
    }

    const handleEditStep = (step) => {
        setEditStep(step)
        setShowDialog(true)
    }

    const handleOnCancel =  e => {
        setShowDialog(false)
    }

    return (
        <div className="min-w-full h-[60vh] relative">
            <div>
                <ul role="list" className="divide-y divide-black min-w-full h-96 overflow-y-scroll">
                    {
                        steps?.map((step, index) => {
                            return (
                                <li className="py-3 sm:py-4 border-b min-w-full" key={index}>
                                    <div className="flex items-start space-x-4">
                                        <div className="flex-shrink-0">
                                            <img className="w-8 h-8 rounded-full border overflow-hidden" src={step?.image ?? PLACEHOLDER_IMG} alt="ingredient image" />
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <p className="text-sm font-medium text-black">
                                                {step.title}
                                            </p>
                                            <small>{step?.description}</small>
                                            
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="inline-flex items-center justify-center text-base font-semibold text-black">
                                                <PencilAltIcon className="h-6 w-6 text-black cursor-pointer mr-1" onClick={() => { handleEditStep(step) }} />
                                                <TrashIcon className="h-6 w-6 text-red-600 cursor-pointer" onClick={() => { handleRemoveStep(index) }} />
                                            </div>
                                            <small className="text-xs mt-1">{step?.timer}</small>
                                        </div>
                                        
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <button
                onClick={() => { setShowDialog(true) }}
                className="border border-black text-center block w-full absolute bottom-1 left-0 py-2 rounded hover:bg-black hover:text-white"
            >Create Step</button>

            <CreateStepDialog 
                showDialog={showDialog} 
                onCancel={handleOnCancel} 
                onUpdate={handleOnUpdate} 
                step={editStep}
            />
        </div>
    )
}

