/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/** lib */
import Loading from '../UI/Loading'

/** state */
import { getUserRecipes } from '../../actions/user.actions'

/** components */

export default function CustomRecipeList() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const recipes = useSelector(state => state?.user_data?.user_recipes)

    const [loading, setLoading] = useState(false)

    const handleClick = (recipeId) => {
        navigate(`/user-recipes/${recipeId}`)
    }

    /**
     * Hooks
     */
    useEffect(() => {
        setLoading(true)

        dispatch(getUserRecipes()).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    if(loading) {
        return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }

    return (
        <div className="w-full h-[70vh]">
            <section className="antialiased text-black px-1">
                <div className="p-3 w-full lg:w-2/3">
                    <div className="overflow-x-auto">
                        { 
                            recipes &&
                                <>
                                    {
                                        recipes.map((recipe, index) => (
                                            <div key={index} className="w-full p-1 px-2 mb-4 hover:bg-black hover:text-white">
                                                <div className="flex-1 border-b border-black cursor-pointer" 
                                                    onClick={() => { handleClick(recipe?.user_recipe_id) }}
                                                >    
                                                    <div>                                                
                                                        <div className="font-light pb-2 flex justify-between">
                                                            <small className="text-left text-lg block">{ recipe.name }</small>
                                                            <small className="text-right text-xs block">Serves: {recipe.serves}</small>
                                                        </div>
                                                        <div className="text-left flex-wrap text-sm flex-shrink-1 py-1">{recipe.description}</div>
                                                    </div>               
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>        
                        }
                    </div>
                </div>
            </section>
        </div>
    )

}