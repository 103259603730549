/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

/** lib */
import Loading from '../UI/Loading'
import { validateBetaCode, register } from '../../services/auth.service'

/** state */
import { login } from '../../actions/auth.actions'
import { 
    getSavedRecipes, 
    getUserMealplans, 
    getShoppinglists, 
    getSharedWithMe 
} from '../../actions/user.actions'

export default function RegisterForm() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { isLoggedIn } = useSelector(state => state.auth)
    
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [registering, setRegistering] = useState(false)

    const [promoCode, setPromoCode] = useState('')
    const [unmaskPasswordState, setUnmaskPasswordState] = useState('password')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const promoCode = urlParams.get('promo_code')
    
        if (promoCode) {
            setPromoCode(promoCode)
        }
    }, [])

    useEffect(() => { console.warn('promoCode', promoCode) }, [promoCode])

    if (isLoggedIn) {
        return <Navigate to="/user-dashboard" />
    }

    const handleSubmit = async e => {
        e.preventDefault()

        if (!name || !/^[a-zA-Z0-9_-]{6,}$/.test(name)) {
            setErrorMessage('Invalid username, must be 6 or more characters. Letters numbers, dash and underscore allowed only.')
            return
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        if (!emailRegex.test(email)) {
            setErrorMessage('Invalid email address')
            return
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match')
            return
        } 

        setRegistering(true)
        setErrorMessage('')
                    
        // complete registration
        register({ 
            name, 
            email, 
            password, 
            password_confirmation: confirmPassword, 
            beta_code: promoCode
        }).then(() => {
            setRegistering(false)
            // setLoading(true)
            
            // login
            dispatch(login({ email, password })).then((r) => {
                dispatch(getSavedRecipes())
                dispatch(getUserMealplans())
                dispatch(getShoppinglists())
                dispatch(getSharedWithMe())
                setLoading(false)
                navigate('/user-dashboard?first_login=true')
            }).catch(() => {
                setErrorMessage('Error logging in')
                setLoading(false)
            })

        }).catch((error) => {
            setErrorMessage('Error with registration')
            setRegistering(false)
        })      
    }

    return (
        <>
            <style scoped>
                {`
                    input {
                        all: unset;
                        background: #000 !important;
                        color: #fff !important;
                        border: 1px solid #fff;
                        font-size: 12px;
                        width: 100%; 
                        box-sizing: border-box;
                        -webkit-box-sizing:border-box;
                        -moz-box-sizing: border-box;
                    }
                    
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        transition: all 5000s ease-in-out 0s;
                    }
                `}
            </style>

            {
                promoCode?.length !== 8 && !loading &&
                    <div className="pt-8 px-8">
                        <label className="block text-white text-sm font-light mb-2 w-full" htmlFor="promo_code">
                            Invite code
                        </label>
                        <input 
                            className="border rounded w-full text-sm py-2 px-3 text-white" 
                            id="promo_code"
                            value={promoCode} 
                            type="text" 
                            placeholder="AB12CD34"
                            onChange={e => {
                                setPromoCode(e.target.value)
                            }} 
                        />
                    </div>

            }

            { loading && <Loading /> }   

            {
                !loading && promoCode?.length === 8 &&
                    <form className="px-8">
                        <div className="text-white text-xs text-center block mt-2 font-light">PROMO CODE: {promoCode}</div>
                        <div className="mb-2 pt-8">
                            <label className="block text-white text-xs font-light mb-2 w-full" htmlFor="username">
                                Username
                            </label>
                            <input 
                                className="border rounded w-full text-xs py-2 px-3 text-white" 
                                id="username" 
                                type="text"
                                role="presentation"
                                autoComplete="new-password" 
                                placeholder="Username"
                                onChange={e => setName(e.target.value)} 
                            />
                        </div>

                        <div className="mb-2">
                        <label className="block text-white text-xs font-light mb-2 w-full" htmlFor="email">
                                Email
                            </label>
                            <input 
                                className="border rounded w-full text-xs py-2 px-3 text-black" 
                                id="email" 
                                type="text" 
                                placeholder="Email Address"
                                onChange={e => setEmail(e.target.value)} 
                            />
                        </div>

                        <div className="mb-2 relative">
                        <label className="block text-white text-xs font-light mb-2 w-full" htmlFor="password">
                                Password
                            </label>
                            <input 
                                className="border rounded w-full text-xs py-2 px-3 text-white" 
                                id="password" 
                                type={unmaskPasswordState}
                                autoComplete="new-password" 
                                placeholder=""
                                onChange={e => setPassword(e.target.value)} 
                            />

                            <div className="absolute right-2 bottom-[4px]">
                                {
                                    unmaskPasswordState === 'password'
                                        ? 
                                            <button  type="button" onClick={() => setUnmaskPasswordState('text') }>
                                                <EyeIcon className="text-white w-4 h-4"/>
                                            </button> 
                                        : <button className="text-white" type="button" onClick={() => setUnmaskPasswordState('password') }>
                                            <EyeOffIcon className="text-white w-4 h-4" />
                                        </button> 
                                }  
                            </div>
                        </div>

                        <div className="mb-6 relative">
                            <label className="block text-white text-xs font-light mb-2 w-full" htmlFor="password_confirm">
                                Confirm Password
                            </label>
                            <input 
                                className="border rounded w-full text-xs py-2 px-3 text-white" 
                                id="password_confirm" 
                                type={unmaskPasswordState}
                                autocomplete="new-password" 
                                placeholder=""
                                onChange={e => setConfirmPassword(e.target.value)} 
                            />

                            <div className="absolute right-2 bottom-[4px]">
                                {
                                    unmaskPasswordState === 'password'
                                        ? 
                                            <button  type="button" onClick={() => setUnmaskPasswordState('text') }>
                                                <EyeIcon className="text-white w-4 h-4"/>
                                            </button> 
                                        : <button className="text-white" type="button" onClick={() => setUnmaskPasswordState('password') }>
                                            <EyeOffIcon className="text-white w-4 h-4" />
                                        </button> 
                                }  
                            </div>
                        </div>

                        <div className="flex items-center justify-center pt-2">
                            <button             
                                className={`${registering ? 'opacity-50' : ''} bg-black text-white px-4 rounded h-9 border border-white w-[200px] text-sm font-light block mx-auto`} 
                                type="button"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>

                        <div className="text-center text-red-500 text-xs py-2">{ errorMessage }</div>
                    </form>
            }
        </>
    )
}
