/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** state */
import { getCustomRecipe } from '../../actions/user.actions'

/** components */
import { Card } from '../../components/UI/Card'
import Loading from '../UI/Loading'
import RecipeDetailItem from './RecipeDetailItem'
import IngredientsDropdown from '../UI/IngredientsDropdown'

export default function CustomRecipeDetail() {
    const location = useLocation()
    const dispatch = useDispatch()

    const user_recipe_id = location.pathname.split('/')[2]

    const userRecipe = useSelector(state => state.user_data.user_recipes
        .find(r => r.user_recipe_id === parseInt(user_recipe_id))
    )

    const [loading, setLoading] = useState(false);
    
    /**
     * Hooks
     */
    useEffect(() => {
        setLoading(true)
        try {
            setLoading(false)
        } catch (error) {
            console.warn(error)
            setLoading(false)
        }
        
    }, [userRecipe])

    if(loading || !userRecipe) {
        return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }

    return (
        <>
            {
                userRecipe &&
                    <div className="flex flex-col w-full pt-16">        
                    
                        <Card title={userRecipe.name} className="w-full">
                            <div className="w-full flex justify-center">
                                <div className="flex flex-col-reverse w-full">
                                    <div className="w-full">
                                        <RecipeDetailItem recipe={userRecipe} canSave={false} />

                                        <div className="">
                                            {   
                                                userRecipe.steps.map((step, index) => {
                                                    return (
                                                        <div className="border-b border-black py-4" key={index}>
                                                            <div className="flex items-center">
                                                                <span className="block text-white bg-black px-2">{index + 1}</span>
                                                                <label className="lowercase pl-2 pb-1 text-black">{step.title}</label>
                                                            </div>
                
                                                            <div className="flex">
                                                                <p className="pl-4 py-4 whitespace-pre-wrap text-base">
                                                                    {step.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <IngredientsDropdown ingredients={userRecipe?.ingredients} />
                    </div>
            }
        </>        
    )
}